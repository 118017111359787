@import '../../assets/styles/colors.scss';

.switch {
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;

  &__item {
    width: 100%;
    flex: 1;
  }

  &__link {
    border-radius: 0 !important;
    background-color: $white;
    color: $very-light-grey;
    font-weight: 600;
    font-size: 0.9em;
    text-align: center;
    padding: 0.5em;

    &--rounded-left {
      border-radius: 2rem 0 0 2rem !important;
    }

    &--rounded-right {
      border-radius: 0 2rem 2rem 0 !important;
    }

    &[aria-selected='true'] {
      background-color: $purple;
    }

    // For 3 switches
    @media (max-width: 436px) {
      padding: 0.5em 0;
    }
  }

  &__icon {
    margin-right: 1em;
    width: auto;
  }

  // For 3 switches
  @media (max-width: 436px) {
    &__link {
      font-size: 0.8em;
      padding: 0.5em 0.2em;
    }

    &__icon {
      width: 100% !important;
    }
  }
}

.search-bar {
  width: 100%;

  &__input {
    height: 50px;
    border: 1px solid $purple;
    border-radius: 1rem 0 0 1rem !important;
    color: $dark-grey;

    &::-webkit-input-placeholder {
      /* Chrome/Opera/Safari */
      color: #e5e5e5;
    }
    &::-moz-placeholder {
      /* Firefox 19+ */
      color: #e5e5e5;
    }
    &:-ms-input-placeholder {
      /* IE 10+ */
      color: #e5e5e5;
    }
    &:-moz-placeholder {
      /* Firefox 18- */
      color: #e5e5e5;
    }
  }

  &__input-button {
    border-radius: 0 1rem 1rem 0 !important;
  }
}

.cards-container {
  padding-bottom: 100px;
  overflow-y: auto;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */

  &::-webkit-scrollbar {
    display: none;
  }

  // For smaller mobile devices
  @media (max-width: 420px) {
    height: 65vh;
  }
  @media (max-width: 360px) {
    height: 60vh;
  }
}

.order-card {
  padding: 0.5em;
  width: 100%;
  border-radius: 0.7em;
  margin: 1em 0 1em 0;
  height: fit-content;

  &__title {
    font-size: 1em;
    color: $purple;
    line-height: 1.5em;
    font-weight: 800;

    &--big {
      font-size: 1.2em;
    }
  }

  &__logo {
    display: flex;
    align-items: center;
    padding: 0;
    max-width: 50px;
    max-height: 50px;
    min-width: 50px;
    min-height: 50px;
  }

  &__logo-placeholder {
    color: $light-grey;
    width: 100% !important;
    height: 100%;
  }

  &__label {
    font-size: 0.8em;
    color: $very-light-grey;
    line-height: 1em;

    &--large {
      font-size: 0.8em;
    }
  }

  &__text {
    font-size: 1em;
    color: $dark-grey;

    &--large {
      font-size: 1em;
      font-weight: 800;
    }

    &--phone {
      color: $purple;
    }
  }

  &__button {
    width: 80%;
    max-width: 200px;
  }

  &__return-button {
    margin: 0 1em;
    background-color: $bluish-grey;
    color: white;
    border: none;

    &:hover {
      background-color: $light-grey;
    }

    // For small devices
    @media (max-width: 436px) {
      margin: 0;
    }
  }
}

.state-change-modal {
  .modal-header {
    padding: 1em 1em 0 0;
    border: none;
  }

  .modal-footer {
    padding: 0 1em 1em 0;
    border: none;
  }
}

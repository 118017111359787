@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;600&display=swap');

@import './assets/styles/colors.scss';

$primary: $purple;

@import '~bootstrap/scss/bootstrap';

/* GLOBAL STYLES */
body {
  font-family: 'Inter', sans-serif;
  font-weight: 400;
  color: #000;
  background-color: #f8f9fa;
}

a {
  text-decoration: none;
  transition: all 0.3s linear;
  -moz-transition: all 0.3s linear;
  -o-transition: all 0.3s linear;
  -ms-transition: all 0.3s linear;
  -webkit-transition: all 0.3s linear;
}

a:focus {
  outline: none;
  text-decoration: none;
}

a:hover,
a:active {
  outline: 0;
  text-decoration: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'Roboto', sans-serif;
  margin: 0;
  font-weight: 400;
  color: #000000;
}

samp {
  font-family: 'Roboto', sans-serif;
}

h1 a,
h2 a,
h3 a,
h4 a,
h5 a,
h6 a {
  color: inherit;
}

input:not([type='radio']):not([type='checkbox']) {
  -webkit-appearance: none;
  -webkit-border-radius: 0px;
  border-radius: 0px;
}

input[type='submit'],
button {
  cursor: pointer;
  -webkit-transition: all 0.4s ease-in-out;
  -moz-transition: all 0.4s ease-in-out;
  -o-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
}

p {
  margin: 0 0 0 0;
  font-weight: 400;
}

ol,
ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

hr {
  background-color: rgba(0, 0, 0, 0.1);
  border: 0;
  height: 1px;
  margin-bottom: 23px;
}

::-webkit-selection {
  background-color: #47d5ff;
  color: #fff;
  text-shadow: none;
}

::-moz-selection {
  background-color: #47d5ff;
  color: #fff;
  text-shadow: none;
}

::selection {
  background-color: #47d5ff;
  color: #fff;
  text-shadow: none;
}

::-webkit-input-placeholder {
  color: #858585;
  opacity: 1;
  -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=100)';
  filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=100)';
}

::-moz-placeholder {
  color: #858585;
  opacity: 1;
  -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=100)';
  filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=100)';
}

:-ms-input-placeholder {
  color: #858585;
  opacity: 1;
  -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=100)';
  filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=100)';
}

// Navbar dropdowns modifiers
#user-menu-dropdown {
  color: white;
}

// Overwrite bootstrap row margin
.form-row,
.row,
.col {
  margin: 0;
}

// Overwrite bootstrap table styles
.table {
  & thead {
    & :first-child {
      text-align: left;
    }
    & th {
      text-align: center;
    }
  }

  & tbody tr {
    & :first-child {
      text-align: left;
    }
    & td {
      text-align: center;
    }
  }
}

// Overwrite checkbox size for React Boostrap
.form-check {
  line-height: 2rem;
}

.form-check-input {
  width: 1.5rem;
  height: 1.5rem;
}

.form-check-label {
  margin-left: 1.5rem;
}

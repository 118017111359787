@import '../../assets/styles/colors.scss';

/* Auth Screen */
.login {
  width: 100%;
  flex-direction: column;
  align-self: center;
  max-width: 450px;

  &__title {
    font-size: 1.5em;
    line-height: 0.5em;
    font-weight: 600;
    letter-spacing: -0.08px;
    color: $dark-grey;
  }

  &__error {
    font-size: 0.8em;
    position: absolute;
    top: 4em;
  }

  &__input {
    padding-left: 2.5rem;
    height: 3rem;
    border: 1px solid #e5e5e5;
    color: #7e8389;

    &:focus {
      outline: 0px !important;
      -webkit-appearance: none;
      box-shadow: none !important;
    }

    &--rounded-top {
      margin-top: 1em;
      border-radius: 0.5rem 0.5rem 0 0 !important;
    }

    &--rounded-bottom {
      margin-bottom: 2.5em;
      border-radius: 0 0 0.5rem 0.5rem !important;
    }
  }

  &__icon {
    position: absolute;
    top: 1rem;
    left: 1rem;
    color: $dark-grey;
  }

  &__button {
    background-color: $purple;
    font-size: 1.2rem;
    line-height: 1.1rem;
    color: $white;
    padding: 1rem 1.2rem;
    width: 100%;
    border: 0;
    border-radius: 0.5rem;
    font-weight: 600;
  }

  &__text {
    font-size: 14px;
    color: $light-grey;
    font-weight: 600;
    margin-bottom: 20px;

    &--dark-grey {
      color: $dark-grey;
      cursor: pointer;
    }
  }

  &__logo {
    width: 100%;
  }
}

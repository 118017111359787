$purple: #5f36c4;
$purple-light: #8156ec;
$blue: #47d5ff;
$blue-light: rgb(113, 222, 228);
$green: #74c69b;
$bluish-grey: #77869e;
$light-grey: #979797;
$very-light-grey: #c4c4c4;
$dark-grey: #424242;
$white: #ffffff;
